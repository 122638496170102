var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[(_vm.getPermissionInterface('Insertion BDD'))?_c('router-link',{attrs:{"to":"/insertion-bdd"}},[_vm._v(" Insertion BDD ")]):_vm._e(),(
      _vm.getPermissionInterface('Analyse et validation') && _vm.computedIsoCurenType
    )?_c('router-link',{attrs:{"to":"/rapport"}},[_vm._v(" Analyse et validation ")]):_vm._e(),(
      _vm.getPermissionInterface('Analyse et validation TH') &&
        _vm.computedThCurenType
    )?_c('router-link',{attrs:{"to":"/rapportTh"}},[_vm._v(" Analyse et validation ")]):_vm._e(),(
      (_vm.computeReponsableDepot ||
        _vm.computeReponsableCommercial ||
        _vm.computeReponsablePoseur) &&
        _vm.computedIsoCurenType
    )?_c('router-link',{attrs:{"to":"/validation-salaire"}},[_vm._v(" Validation des salaires ")]):_vm._e(),(
      (_vm.computeReponsableDepot || _vm.computeReponsableCommercial) &&
        _vm.computedThCurenType
    )?_c('router-link',{attrs:{"to":"/commerciaux/validation-paie"}},[_vm._v(" Validation des salaires ")]):_vm._e(),(_vm.computedCommerciaux && _vm.computedIsoCurenType)?_c('router-link',{attrs:{"to":"/gestionFactureCommerciaux"}},[_vm._v(" Gestion Facture ")]):_vm._e(),(_vm.computedCommerciaux && _vm.computedThCurenType)?_c('router-link',{attrs:{"to":"/commerciaux/validation-aff"}},[_vm._v(" Validation de AAF ")]):_vm._e(),(
      (_vm.getPermissionInterface('Validation salaires') ||
        _vm.getPermissionInterface('Validation indépendants') ||
        _vm.getPermissionInterface('Suivi indépendants') ||
        _vm.getPermissionInterface('Récapitulatif commerciaux par zone')) &&
        _vm.computedIsoCurenType
    )?_c('router-link',{class:{
      'router-link-active': _vm.ComputedRouterLinkEmployeActive,
      a: !_vm.ComputedRouterLinkEmployeActive
    },attrs:{"to":_vm.computeSuperAdmin ? '/employes/validation-salaire-admin' : '/employes'}},[_vm._v(" Employés ")]):_vm._e(),(
      (_vm.getPermissionInterface('Gestion des facture regie') ||
        _vm.getPermissionInterface('list document regie') ||
        _vm.getPermissionInterface('validation cout regie') ||
        _vm.getPermissionInterface('suivi regie') ||
        _vm.getPermissionInterface('Recap regie par zone')) &&
        _vm.computedIsoCurenType
    )?_c('router-link',{class:{
      'router-link-active': _vm.ComputedRouterLinkRegieActive,
      a: !_vm.ComputedRouterLinkRegieActive
    },attrs:{"to":_vm.computeSuperAdmin ? '/regies/gestion-facture' : '/regies'}},[_vm._v(" Régies ")]):_vm._e(),(
      (_vm.getPermissionInterface('Suivi des paiement Obligé') ||
        _vm.getPermissionInterface('Gestions des appels à paiement Obligé')) &&
        _vm.computedThCurenType
    )?_c('router-link',{class:{
      'router-link-active': _vm.ComputedRouterLinkObligeeActive,
      a: !_vm.ComputedRouterLinkObligeeActive
    },attrs:{"to":_vm.computeSuperAdmin
        ? '/obligee/gestion-appels-paiement-obligee'
        : '/obligee'}},[_vm._v(" Factures Obligées ")]):_vm._e(),(
      (_vm.getPermissionInterface('Validation de paie commerciaux Th') ||
        _vm.getPermissionInterface('Validation des AAF commerciaux') ||
        _vm.getPermissionInterface('Suivi de paiement des Indépendants Th') ||
        _vm.getPermissionInterface('Suivi des acomptes') ||
        _vm.getPermissionInterface('Récapitulatif Commemciaux par filiale Th')) &&
        _vm.computedThCurenType
    )?_c('router-link',{class:{
      'router-link-active': _vm.ComputedRouterLinkCommerciauxActive,
      a: !_vm.ComputedRouterLinkCommerciauxActive
    },attrs:{"to":_vm.computeSuperAdmin
        ? '/commerciaux/validation-paie'
        : _vm.computedRouterCommerciaux}},[_vm._v(" Gestion des commerciaux ")]):_vm._e(),(
      (_vm.getPermissionInterface('Gestion des AAF régie Th') ||
        _vm.getPermissionInterface('Suivi des régies Th') ||
        _vm.getPermissionInterface('Récapitulatif régie par filiale Th')) &&
        _vm.computedThCurenType
    )?_c('router-link',{class:{
      'router-link-active': _vm.ComputedRouterLinkRegieThActive,
      a: !_vm.ComputedRouterLinkRegieThActive
    },attrs:{"to":_vm.computeSuperAdmin ? '/regies-th/gestion-aaf' : _vm.computedRouterRegie}},[_vm._v(" Gestion des régies ")]):_vm._e(),(_vm.ComputedRouterLinkFraisThShow)?_c('router-link',{class:{
      'router-link-active': _vm.ComputedRouterLinkFraisThActive,
      a: !_vm.ComputedRouterLinkFraisThActive
    },attrs:{"to":_vm.computeSuperAdmin ? '/frais-th/bdd' : _vm.computedRouterFrais}},[_vm._v(" Gestion des frais ")]):_vm._e(),(_vm.computeAdminRegie && _vm.computedIsoCurenType)?_c('router-link',{attrs:{"to":"/gestion-facture-regie"}},[_vm._v(" Gestion des factures ")]):_vm._e(),(_vm.computeAdminRegie && _vm.computedThCurenType)?_c('router-link',{attrs:{"to":"/gestion-aaf-regie"}},[_vm._v(" Gestion AAF ")]):_vm._e(),(_vm.getPermissionInterface('Statistique') && _vm.computedIsoCurenType)?_c('router-link',{class:{
      'router-link-active': _vm.ComputedRouterLinkStatistiqueActive,
      a: !_vm.ComputedRouterLinkStatistiqueActive
    },attrs:{"to":"/statistique/employeur"}},[_vm._v(" Statistiques ")]):_vm._e(),(_vm.computeAdminRegie && _vm.computedIsoCurenType)?_c('router-link',{attrs:{"to":"/gestion-facture-sous-regie"}},[_vm._v(" Gestion des factures sous régies ")]):_vm._e(),(_vm.computedCommercialRegie)?_c('router-link',{attrs:{"to":"/gestion-facture-commercial-regie"}},[_vm._v(" Gestion des factures sous régies ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }