<template>
  <div class="menu">
    <router-link
      to="/insertion-bdd"
      v-if="getPermissionInterface('Insertion BDD')"
    >
      Insertion BDD
    </router-link>
    <router-link
      v-if="
        getPermissionInterface('Analyse et validation') && computedIsoCurenType
      "
      to="/rapport"
    >
      Analyse et validation
    </router-link>
    <router-link
      v-if="
        getPermissionInterface('Analyse et validation TH') &&
          computedThCurenType
      "
      to="/rapportTh"
    >
      Analyse et validation
    </router-link>
    <router-link
      v-if="
        (computeReponsableDepot ||
          computeReponsableCommercial ||
          computeReponsablePoseur) &&
          computedIsoCurenType
      "
      to="/validation-salaire"
    >
      Validation des salaires
    </router-link>
    <router-link
      v-if="
        (computeReponsableDepot || computeReponsableCommercial) &&
          computedThCurenType
      "
      to="/commerciaux/validation-paie"
    >
      Validation des salaires
    </router-link>
    <router-link
      v-if="computedCommerciaux && computedIsoCurenType"
      to="/gestionFactureCommerciaux"
    >
      Gestion Facture
    </router-link>
    <router-link
      v-if="computedCommerciaux && computedThCurenType"
      to="/commerciaux/validation-aff"
    >
      Validation de AAF
    </router-link>
    <router-link
      v-if="
        (getPermissionInterface('Validation salaires') ||
          getPermissionInterface('Validation indépendants') ||
          getPermissionInterface('Suivi indépendants') ||
          getPermissionInterface('Récapitulatif commerciaux par zone')) &&
          computedIsoCurenType
      "
      :to="
        computeSuperAdmin ? '/employes/validation-salaire-admin' : '/employes'
      "
      :class="{
        'router-link-active': ComputedRouterLinkEmployeActive,
        a: !ComputedRouterLinkEmployeActive
      }"
    >
      Employés
    </router-link>
    <router-link
      v-if="
        (getPermissionInterface('Gestion des facture regie') ||
          getPermissionInterface('list document regie') ||
          getPermissionInterface('validation cout regie') ||
          getPermissionInterface('suivi regie') ||
          getPermissionInterface('Recap regie par zone')) &&
          computedIsoCurenType
      "
      :to="computeSuperAdmin ? '/regies/gestion-facture' : '/regies'"
      :class="{
        'router-link-active': ComputedRouterLinkRegieActive,
        a: !ComputedRouterLinkRegieActive
      }"
    >
      Régies
    </router-link>

    <router-link
      v-if="
        (getPermissionInterface('Suivi des paiement Obligé') ||
          getPermissionInterface('Gestions des appels à paiement Obligé')) &&
          computedThCurenType
      "
      :to="
        computeSuperAdmin
          ? '/obligee/gestion-appels-paiement-obligee'
          : '/obligee'
      "
      :class="{
        'router-link-active': ComputedRouterLinkObligeeActive,
        a: !ComputedRouterLinkObligeeActive
      }"
    >
      Factures Obligées
    </router-link>
    <router-link
      v-if="
        (getPermissionInterface('Validation de paie commerciaux Th') ||
          getPermissionInterface('Validation des AAF commerciaux') ||
          getPermissionInterface('Suivi de paiement des Indépendants Th') ||
          getPermissionInterface('Suivi des acomptes') ||
          getPermissionInterface('Récapitulatif Commemciaux par filiale Th')) &&
          computedThCurenType
      "
      :to="
        computeSuperAdmin
          ? '/commerciaux/validation-paie'
          : computedRouterCommerciaux
      "
      :class="{
        'router-link-active': ComputedRouterLinkCommerciauxActive,
        a: !ComputedRouterLinkCommerciauxActive
      }"
    >
      Gestion des commerciaux
    </router-link>
    <router-link
      v-if="
        (getPermissionInterface('Gestion des AAF régie Th') ||
          getPermissionInterface('Suivi des régies Th') ||
          getPermissionInterface('Récapitulatif régie par filiale Th')) &&
          computedThCurenType
      "
      :to="computeSuperAdmin ? '/regies-th/gestion-aaf' : computedRouterRegie"
      :class="{
        'router-link-active': ComputedRouterLinkRegieThActive,
        a: !ComputedRouterLinkRegieThActive
      }"
    >
      Gestion des régies
    </router-link>
    <router-link
      v-if="ComputedRouterLinkFraisThShow"
      :to="computeSuperAdmin ? '/frais-th/bdd' : computedRouterFrais"
      :class="{
        'router-link-active': ComputedRouterLinkFraisThActive,
        a: !ComputedRouterLinkFraisThActive
      }"
    >
      Gestion des frais
    </router-link>
    <router-link
      v-if="computeAdminRegie && computedIsoCurenType"
      to="/gestion-facture-regie"
    >
      Gestion des factures
    </router-link>
    <!-- <router-link
      v-if="computedThCurenType && getPermissionInterface('Facture libre')"
      to="/facture-libre"
    >
      Gestion des factures libres
    </router-link> -->
    <router-link
      v-if="computeAdminRegie && computedThCurenType"
      to="/gestion-aaf-regie"
    >
      Gestion AAF
    </router-link>
    <router-link
      v-if="getPermissionInterface('Statistique') && computedIsoCurenType"
      to="/statistique/employeur"
      :class="{
        'router-link-active': ComputedRouterLinkStatistiqueActive,
        a: !ComputedRouterLinkStatistiqueActive
      }"
    >
      Statistiques
    </router-link>
    <router-link
      v-if="computeAdminRegie && computedIsoCurenType"
      to="/gestion-facture-sous-regie"
    >
      Gestion des factures sous régies
    </router-link>
    <router-link
      v-if="computedCommercialRegie"
      to="/gestion-facture-commercial-regie"
    >
      Gestion des factures sous régies
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'getCurrentType', 'getPermissionInterface']),
    computeSuperAdmin: function() {
      return this.getUserData && this.getUserData.role === 'admin';
    },
    computeReponsableDepot: function() {
      return this.getUserData && this.getUserData.role === 'responsable_depot';
    },
    computeReponsableCommercial: function() {
      return (
        this.getUserData && this.getUserData.role === 'responsable_commercial'
      );
    },
    computeReponsablePoseur: function() {
      return (
        this.getUserData && this.getUserData.role === 'responsable_poseurs'
      );
    },
    computeAdminRegie: function() {
      return this.getUserData && this.getUserData.role === 'admin_regie';
    },
    utilsRouterRegieLinkActive: function() {
      return (
        this.$route.name === 'GestionFacture' ||
        this.$route.name === 'DocumentRegies' ||
        this.$route.name === 'validation-cout'
      );
    },
    utilsRouterEmployeLinkActive: function() {
      return this.$route.name === 'validationSalaire';
    },
    computedCommercialRegie: function() {
      return this.getUserData && this.getUserData.role === 'commercial_regie';
    },
    computedIsoCurenType: function() {
      return (
        this.getCurrentType &&
        this.getCurrentType.value &&
        this.getCurrentType.value === 'iso'
      );
    },
    computedThCurenType: function() {
      return (
        this.getCurrentType &&
        this.getCurrentType.value &&
        (this.getCurrentType.value === 'Th' ||
          this.getCurrentType.value === 'libres' ||
          this.getCurrentType.value === 'sci')
      );
    },
    computedCommerciaux: function() {
      return (
        this.getUserData &&
        (this.getUserData.role === 'Responsable planning' ||
          this.getUserData.role === 'Commercial sedentaire' ||
          this.getUserData.role === 'Admin GE' ||
          this.getUserData.role === 'Commercial terrain' ||
          this.getUserData.role === 'Pre visiteur' ||
          this.getUserData.role === 'Poseur' ||
          this.getUserData.role === 'Co poseur')
      );
    },
    ComputedRouterLinkEmployeActive: function() {
      return (
        this.$route.name === 'validationSalaireCommerciaux' ||
        this.$route.name === 'SuiviIndependant' ||
        this.$route.name === 'RecapCommerciaux'
      );
    },
    ComputedRouterLinkRegieActive: function() {
      return (
        this.$route.name === 'DocumentRegies' ||
        this.$route.name === 'validation-cout' ||
        this.$route.name === 'SuiviRegie' ||
        this.$route.name === 'RecapRegie'
      );
    },
    ComputedRouterLinkRegieThActive: function() {
      return (
        this.$route.name === 'gestionAAF' ||
        this.$route.name === 'SuiviRegieTh' ||
        this.$route.name === 'RecapRegieTh'
      );
    },
    ComputedRouterLinkStatistiqueActive: function() {
      return this.$route.name === 'regie';
    },
    ComputedRouterLinkObligeeActive: function() {
      return (
        this.$route.name === 'suiviPaiementObligee' ||
        this.$route.name === 'gestionAppelPaiementObligee'
      );
    },
    ComputedRouterLinkCommerciauxActive: function() {
      return (
        this.$route.name === 'suiviAcomptes' ||
        this.$route.name === 'validation-paie-th' ||
        this.$route.name === 'validationAaf' ||
        this.$route.name === 'suiviPaiementIndependant' ||
        this.$route.name === 'recapCommerciauxParFiliale'
      );
    },
    ComputedRouterLinkFraisThActive: function() {
      return (
        this.$route.name === 'bdd' ||
        this.$route.name === 'visionFiliale' ||
        this.$route.name === 'comercialCactus' ||
        this.$route.name === 'bureauEtude' ||
        this.$route.name === 'comptaRh' ||
        this.$route.name === 'energieInvest' ||
        this.$route.name === 'simulationFactureFrais'
      );
    },
    ComputedRouterLinkFraisThShow: function() {
      return (
        (this.getPermissionInterface('Frais BDD') ||
          this.getPermissionInterface('Récapitulatif gestion des frais') ||
          this.getPermissionInterface('Gestion Commercial Cactus') ||
          this.getPermissionInterface('Gestion Bureau Etude') ||
          this.getPermissionInterface('Gestion RH') ||
          this.getPermissionInterface('Gestion Compta') ||
          this.getPermissionInterface('Gestion informatique web abondance') ||
          this.getPermissionInterface('Gestion Invest') ||
          this.getPermissionInterface('Simulation Facture Frais')) &&
        this.computedThCurenType
      );
    },
    computedRouterFrais: function() {
      if (this.getPermissionInterface('Frais BDD')) {
        return '/frais-th/bdd';
      }
      if (this.getPermissionInterface('Récapitulatif gestion des frais')) {
        return '/frais-th/vision-filiale';
      }
      if (this.getPermissionInterface('Gestion Commercial Cactus')) {
        return '/frais-th/comercial-cactus';
      }
      if (this.getPermissionInterface('Gestion Bureau Etude')) {
        return '/frais-th/bureau-etude';
      }
      if (this.getPermissionInterface('Gestion RH')) {
        return '/frais-th/rh';
      }
      if (this.getPermissionInterface('Gestion Compta')) {
        return '/frais-th/compta';
      }
      if (this.getPermissionInterface('Gestion Invest')) {
        return '/frais-th/energie-invest';
      }
      if (this.getPermissionInterface('Gestion informatique web abondance')) {
        return '/frais-th/web-abondance';
      }

      return '/frais-th';
    },
    computedRouterCommerciaux: function() {
      if (this.getPermissionInterface('Validation de paie commerciaux Th')) {
        return '/commerciaux/validation-paie-th';
      }
      if (this.getPermissionInterface('Validation des AAF commerciaux')) {
        return '/commerciaux/validation-aff';
      }
      if (
        this.getPermissionInterface('Suivi de paiement des Indépendants Th')
      ) {
        return '/commerciaux/suivi-paiement-independant';
      }
      if (
        this.getPermissionInterface('Récapitulatif Commemciaux par filiale Th')
      ) {
        return '/commerciaux/recap-commerciaux-par-filiale';
      }
      if (this.getPermissionInterface('Suivi des acomptes')) {
        return '/commerciaux/suivi-acomptes';
      }
      return '/commerciaux';
    },
    computedRouterRegie: function() {
      if (this.getPermissionInterface('Gestion des AAF régie Th')) {
        return '/regies-th/gestion-aaf';
      }
      if (this.getPermissionInterface('Suivi des régies Th')) {
        return '/regies-th/suivi-regie-th';
      }
      if (this.getPermissionInterface('Récapitulatif régie par filiale Th')) {
        return '/regies-th/recap-regie-th';
      }
      return '/regies-th';
    }
  }
};
</script>

<style lang="scss" scoped>
.menu > .Search-style-component {
  margin: auto !important;
}
.menu {
  display: flex;
  align-items: center;
  margin-left: 10px;
  height: 100%;
  a {
    display: block;
    overflow: hidden;
    font-weight: 600;
    padding: 12px 8px;
    font-size: 11px;
    line-height: 7px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    color: #5d5d5d;
    border-radius: 25px;
    margin-right: 4px;
    font-family: 'Montserrat', sans-serif;

    &.router-link-active {
      color: #fff;

      background-color: #4d4bac;
    }
    &:hover {
      color: #fff;
      background-color: #9799d6;
    }
  }

  .custom-dropdown {
    height: 31px;
    margin-right: 4px;
  }
}
@media screen and (max-width: 1200px) {
  .menu {
    display: inline;
    a {
      margin: 0px 5px;
    }
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 1200px) {
  .menu .custom-dropdown {
    height: 42px !important;
    button {
      text-align: start;
    }
  }
}
.custom-dropdown {
  .custom-dropdown-button {
    height: 31px;
    background-color: transparent;
    border: none;
    color: #5d5d5d;
    border-radius: 25px;
    margin-right: 4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: 12px;
    font-size: 14px !important;
    line-height: 7px !important;
    &:hover {
      color: #fff;
      background-color: #9799d6;
    }
    &.btn-,
    &.btn-secondary:not(:disabled):not(.disabled).active {
      color: #fff;
      background-color: #4d4bac;
    }
  }
  &.router-link-active {
    background-color: #4d4bac;
    border-radius: 25px;
    button {
      color: white;
    }
    &.custom-dropdown-button {
      color: white;
    }
  }
  .dropdown-menu {
    margin: 0;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #aaaaaa42;
    border: none;
    transform: translate3d(0px, 34px, 0px) !important;
    li {
      margin: 0px 5px;
    }
    .dropdown-item {
      border-radius: 25px;
      margin: 1px 0px;
      padding: 6px 8px;

      &.router-link-active {
        color: #fff;
        background-color: #4d4bac;
      }
      &:active {
        background-color: #4d4bac;
      }
      &:hover {
        color: #fff;
        background-color: #9799d6;
        border-radius: 25px;
      }
    }
  }
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #4d4bac;
  height: 31px;
}
</style>
